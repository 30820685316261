var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 18 } }, [
            _c(
              "div",
              { staticClass: "user-addcount" },
              [
                _c(
                  "el-tabs",
                  {
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "个人信息", name: "second" } },
                      [
                        _c("ul", [
                          _c("li", [
                            _c("p", { staticClass: "title" }, [
                              _vm._v("用户名"),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "desc" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.userInfo.userName) +
                                  "\n                "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("p", { staticClass: "title" }, [_vm._v("姓名")]),
                            _vm._v(" "),
                            _c("p", { staticClass: "desc" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.userInfo.nickName) +
                                  "\n                "
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }