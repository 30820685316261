import _objectSpread from "/Users/adway/Documents/agora.io/ad-manage-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters, mapMutations } from 'vuex';
var path = process.env.VUE_APP_BASE_API;
export default {
  name: 'Person',
  data: function data() {
    return {
      path: path,
      activeName: 'second',
      pwdModify: {}
    };
  },
  computed: _objectSpread({}, mapGetters('user', ['userInfo', 'token'])),
  methods: _objectSpread({}, mapMutations('user', ['ResetUserInfo']))
};